// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-charities-js": () => import("./../src/pages/charities.js" /* webpackChunkName: "component---src-pages-charities-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-covid-js": () => import("./../src/pages/covid.js" /* webpackChunkName: "component---src-pages-covid-js" */),
  "component---src-pages-gallery-js": () => import("./../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-get-involved-js": () => import("./../src/pages/get-involved.js" /* webpackChunkName: "component---src-pages-get-involved-js" */),
  "component---src-pages-goodbye-js": () => import("./../src/pages/goodbye.js" /* webpackChunkName: "component---src-pages-goodbye-js" */),
  "component---src-pages-hall-of-fame-js": () => import("./../src/pages/hall-of-fame.js" /* webpackChunkName: "component---src-pages-hall-of-fame-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ledger-js": () => import("./../src/pages/ledger.js" /* webpackChunkName: "component---src-pages-ledger-js" */),
  "component---src-pages-order-js": () => import("./../src/pages/order.js" /* webpackChunkName: "component---src-pages-order-js" */),
  "component---src-pages-products-js": () => import("./../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-raffle-js": () => import("./../src/pages/raffle.js" /* webpackChunkName: "component---src-pages-raffle-js" */),
  "component---src-pages-stats-js": () => import("./../src/pages/stats.js" /* webpackChunkName: "component---src-pages-stats-js" */),
  "component---src-pages-thank-you-js": () => import("./../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-the-team-js": () => import("./../src/pages/the-team.js" /* webpackChunkName: "component---src-pages-the-team-js" */)
}

